import React from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Container } from './styles';
import NewUser from './new/newUser';
import ListUser from './list/listUser';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(2),
      background: "#2E8B57",
      color: "#FFF"
    },  
    buttonSmall:{
        size: 'small',      
    }
  }),
);


export default function User() {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  
  const dataNF = [
    {
      Data: '07-08-2020',
      NFPrcessada: 21,
      NFFalha: 29,
    },
    {
      Data: '06-08-2020',
      NFPrcessada: 25,
      NFFalha: 25,
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <Container>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Novo
      </Button>
      <br/>
      <br/>
      <NewUser open={open} setOpen={setOpen} />
      <ListUser/>
      </Container>
  );
}