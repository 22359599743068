import React from 'react';

import { Container } from './styles';

const DashBoard = () => { 


return (

<Container>
</Container>
)
};

export default DashBoard;
