import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import GlobalStyle from './global/style';
import MenuPrincipal from './components/Menu/index';
import Routes from './routes/index';

function App() {
  return (
    <ToastProvider>
    <BrowserRouter>
      <MenuPrincipal/>
      <Routes/>
      <GlobalStyle />
    </BrowserRouter>
    </ToastProvider>
  );
}

export default App;
