import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Dashboard from "../pages/dashBoard/index";
import Empresa from "../pages/empresa/index";
import User from "../pages/user/index";
import ConsultaNFCompleta from "../pages/consultaNFCompleta/index";
import ConsultaNFSimples from "../pages/consultaNFSimples/index";
import ConsultaSaldo from "../pages/consultaSaldo/index";
import ProcessaNF from "../pages/processaNF/index";
import Recinto from "../pages/recintos/index";
import URF from "../pages/urf/index";
import Parametros from "../pages/parametros/index";

const Routes = () => (
<Switch>
  <Route path="/" exact component={Dashboard} />
  <Route path="/empresa" component={Empresa} />
  <Route path="/usuario" component={User} />
  <Route path="/consultanfcompleta" component={ConsultaNFCompleta} />
  <Route path="/consultanfsimples" component={ConsultaNFSimples} />
  <Route path="/consultasaldo" component={ConsultaSaldo} />
  <Route path="/processanf" component={ProcessaNF} />
  <Route path="/recinto" component={Recinto} />
  <Route path="/urf" component={URF} />
  <Route path="/parametros" component={Parametros} />
</Switch>

);

export default Routes