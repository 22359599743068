import React, { useState, useEffect } from 'react';
import MUIDataTable, {SelectableRows} from "mui-datatables";
import {listRecintos} from '../../../service/recintosServices';

export default function ListRecinto() {
  const [recintodata, setRecintodata] = useState([]);

  useEffect(() => {
  
    listRecintos({
      onSuccess: (data) => {
        setRecintodata(data.data);
      }
    });

  }, [])

  const columns = [
    {
     name: "codigo",
     label: "Codigo",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "descricao",
     label: "Descricao",
     options: {
      filter: true,
      sort: false,
     }
    }];


  const none : SelectableRows = "none";

  const options = {
    print: false,
    selectableRows: none
  };

  return (
        <MUIDataTable
          title={"Recintos"}
          data={recintodata}
          columns={columns}
          options={options}
        />
  );
}