import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FlashOn from '@material-ui/icons/FlashOn';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {  useToasts } from 'react-toast-notifications';
import {processar} from '../../../service/consultaNFCompletaService';


const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      margin: theme.spacing(2),
      background: 'linear-gradient(83.07deg,#04580a 0%,#2E8B57 100%)',
      color: "#FFF"
    }, 
    buttonSecond: {
      margin: theme.spacing(2),
      background: 'linear-gradient(83.07deg,#041a58 0%,#3f51b5 100%)',
      color: "#FFF"
    },
    buttonSmall:{
        size: 'small',      
    },
    table: {
    minWidth: 650,
  },
  }),
);


export default function FilterNfCompleta() {
  const classes = useStyles();
  const [values, setValues] = useState({
    cnpj: '',
    dataIncial: '',
    dataFinal: ''
  });
  const { addToast } = useToasts();
    

  const handleSubimit = async () => {
      const formData = new FormData();
      formData.append("file", '');
      const request= {
        Cnpj: values.cnpj,
        DataIncial: values.dataIncial,
        DataFinal: values.dataFinal
      };

      if(values.cnpj === '' || values.dataIncial === '' || values.dataFinal === ''){
        addToast('Todos os campos são obrigatórios', {
          appearance: 'warning',
          autoDismiss: true
        });
        return;
        }

   await processar({
        empresaid:"41251bf544de413ca6fe6d019a3c9ad3",
        request,
        onSuccess: (data) =>{
        },
       onError: (error) =>{
         addToast(error.message || error.response.data, {
          appearance: 'error',
          autoDismiss: true
        });
       }});
};

const handleChangeForm = name => event => {
  setValues({ ...values, [name]: event.target.value });
};
  
  return (
    <>
    <Grid container>
    <Grid item xs={12} >
    <TextField       
          helperText="Campo obrigatório." 
          autoFocus
          margin="none"
          id="cnpj"
          label="CNPJ's"
          type="text"
          fullWidth
          onChange={handleChangeForm("cnpj")}
        />
    </Grid>

    </Grid>
    <Grid container>
    <Grid item xs={4}>
    <TextField
        id="dtInicial"
        label="Inicial"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleChangeForm("dataIncial")}
      />
    </Grid>
    <Grid item xs={4}>
    <TextField
        id="dtFinal"
        label="Final"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleChangeForm("dataFinal")}
      />
    </Grid>        
    <Grid item xs={4} >        
    <Button
        variant="contained"
        className={classes.buttonSecond}
        startIcon={<FlashOn />}
        onClick= {handleSubimit}
      >Processar
    </Button>
    </Grid>
    </Grid>
  </>
  );

}
