import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FlashOn from '@material-ui/icons/FlashOn';
import Search from '@material-ui/icons/Search';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import {  useToasts } from 'react-toast-notifications';
import {list} from '../../../service/consultaSaldoService';

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      margin: theme.spacing(2),
      background: 'linear-gradient(83.07deg,#04580a 0%,#2E8B57 100%)',
      color: "#FFF"
    }, 
    buttonSecond: {
      margin: theme.spacing(2),
      background: 'linear-gradient(83.07deg,#041a58 0%,#3f51b5 100%)',
      color: "#FFF"
    },
    buttonSmall:{
        size: 'small',      
    },
    table: {
    minWidth: 650,
  },
  }),
);


export default function FilterSaldo({buscaDados}) {
  const classes = useStyles();
  const [values, setValues] = useState({
    cnpjs: '',
    porto:'',
    ncm:'',
    dataemissaoinicial:'',
    dataemissaofinal:''
  });
  const { addToast } = useToasts();
  

const handleChangeForm = name => event => {
  setValues({ ...values, [name]: event.target.value });
};

const handleConsultar = () => {
  try
  {
    buscaDados(values);
  }
  catch(ex)
  {
    console.log('consultar error:',ex);
    addToast(ex, {
      appearance: 'error',
      autoDismiss: true
    });
  } 
};

  return (
    <>
    <Grid container>
    <Grid item xs={3} >
    <TextField       
          autoFocus
          margin="none"
          id="porto"
          label="Porto"
          type="text"
          onChange={handleChangeForm("porto")}
        />
    </Grid>
    <Grid item xs={3} >
    <TextField       
          autoFocus
          margin="none"
          id="ncm"
          label="NCM"
          type="text"
          onChange={handleChangeForm("ncm")}
        />
    </Grid>
    <Grid item xs={3}>
    <TextField
        id="dtInicial"
        label="Data Emissão Inicial"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleChangeForm("dataemissaoinicial")}
      />
    </Grid>
    <Grid item xs={3}>
    <TextField
        id="dtFinal"
        label="Data Emissão Final"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleChangeForm("dataemissaofinal")}
      />
    </Grid>  
    </Grid>
    <Grid container>  
    <Grid item xs={6} >
    <TextField       
          autoFocus
          margin="none"
          id="cnpjs"
          label="Cnpj's separados por vírgula"
          type="text"
          fullWidth
          onChange={handleChangeForm("cnpjs")}
        />
    </Grid>
    <Grid item xs={6} >        
    <Button
      variant="contained"
      className={classes.buttonSecond}
      startIcon={<Search />}
      onClick= {handleConsultar}
    >
      Consultar
    </Button>
    </Grid>
    </Grid>
  </>
  );

}
