import React, { useState, useEffect } from 'react';
import MUIDataTable, {SelectableRows} from "mui-datatables";
import {listUser} from '../../../service/userServices';

export default function ListUser() {
  const [userdata, setUserdata] = useState([]);

  useEffect(() => {
  
    listUser({
      onSuccess: (data) => {
        setUserdata(data.data);
      }
    });

  }, [])

  const columns = [
    {
     name: "nome",
     label: "Nome",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "email",
     label: "Email",
     options: {
      filter: true,
      sort: false,
     }
    }];


  const none : SelectableRows = "none";

  const options = {
    print: false,
    selectableRows: none
  };

  return (
    <MUIDataTable
    title={"Usuários"}
    data={userdata}
    columns={columns}
    options={options}
  />
  );
}