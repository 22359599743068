import { siscomex } from './instance'

export const salvarFile = async ({
  empresaid,
  formData,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) =>
{
 await siscomex.post(`/v1/empresa/${empresaid}/consulta_completa/salvar`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
})
.then(({ data }) => emitSuccessEvent(data))
.catch((error) => emitErrorEvent(error))
}


export const processar = async ({
  empresaid,
  request,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) =>
{
await  siscomex.post(`/v1/empresa/${empresaid}/consulta_completa/processar`, request)

.then(({ data }) => emitSuccessEvent(data))
.catch((error) => emitErrorEvent(error))
}