import React from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Container } from './styles';
import ListUrf from './list/listUrf';

export default function Urf() {
  const handleClickFind = () => {

  };

  return (
    <Container>
      <Button variant="outlined" color="primary" onClick={handleClickFind}>
        Buscar
      </Button>
      <br/>
      <br/>
      <ListUrf/>
    </Container>
  );
}