import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {  useToasts } from 'react-toast-notifications';
import { Container } from './styles';
import {Salvar} from '../../service/parametrosService';

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      margin: theme.spacing(2),
      background: "#2E8B57",
      color: "#FFF"
    },  
    buttonSmall:{
        size: 'small',      
    }
  }),
);


export default function Parametros() {
  const classes = useStyles();
  const { addToast } = useToasts();
  const [values, setValues] = useState({
    headers: '',
  });


  const handleSalvar = async () => {
    
if(values.headers === ''){
  addToast('Informe a configuração', {
    appearance: 'warning',
    autoDismiss: true
  });
  return;
}

    const formData = new FormData();
    formData.append("headerjson",values.headers);

   await Salvar({
      empresaid:'41251bf544de413ca6fe6d019a3c9ad3',
      formData,
      onSuccess: (data) =>{
        addToast('Salvo com sucesso', {
          appearance: 'success',
          autoDismiss: true
        });
        document.getElementById("header").value = "";
      },
    onError:(error) =>{
      console.log(error);
      addToast(error.message || error.reports, {
        appearance: 'error',
        autoDismiss: true
      });
    }});
  };

  const handleChangeForm = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <Container>
    <Grid container>   
    <Grid item xs={4} >
    <TextField
          id="header"
          label="Parâmetros de requisição"
          multiline
          rows={4}
          defaultValue=""
          variant="outlined"
          onChange={handleChangeForm("headers")}
        />
    </Grid>
    <Grid item xs={4} > 
    <Button variant="outlined" color="primary" onClick={handleSalvar}>
        Gravar
    </Button>
    </Grid>
    </Grid>
      </Container>
  );
}