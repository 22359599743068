import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { error } from 'console';

export default function NewEmpresa(props : any) {
  const [
    hasError,
    setHasError
   ] = useState("");


  const handleClose = () => {
    props.setOpen(false);
  };

  const handleSave = () => {
  };

   const handleIsVoid = (text: any, field: string) => {
     console.log(text);
     if(text.value === ""){
        setHasError(field);
     }
     else{
        setHasError("");
      }
  };

  return (
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Empresa</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Informe os dados da empresa.
        </DialogContentText>
        <TextField        
          error={hasError === "razao"}
          helperText="Campo obrigatório." 
          autoFocus
          margin="dense"
          id="razao"
          label="Razão Social"
          type="text"
          fullWidth
        />
        <TextField
          error={hasError === "cnpj"}
          helperText="Campo obrigatório."
          margin="dense"
          id="cnpj"
          label="CNPJ"
          type="text"
          fullWidth
        />
        <TextField
          error={hasError === "telefone"}
          helperText="Campo obrigatório."
          margin="none"
          id="telefone"
          label="Telefone"
          type="text"
          fullWidth
        />
        <TextField
          error={hasError === "endereco"}
          helperText="Campo obrigatório."
          margin="none"
          id="endereco"
          label="Endereco"
          type="text"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSave} color="primary">
          Gravar
        </Button>
      </DialogActions>
    </Dialog>
  );
  };