import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FlashOn from '@material-ui/icons/FlashOn';
import GridOn from '@material-ui/icons/GridOn';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import {  useToasts } from 'react-toast-notifications';
import {file,chaves} from '../../../service/consultaNFSimplesService';
import InputFile from '../../../components/InputFile/index'

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      margin: theme.spacing(2),
      background: 'linear-gradient(83.07deg,#04580a 0%,#2E8B57 100%)',
      color: "#FFF"
    }, 
    buttonSecond: {
      margin: theme.spacing(2),
      background: 'linear-gradient(83.07deg,#041a58 0%,#3f51b5 100%)',
      color: "#FFF"
    },
    buttonSmall:{
        size: 'small',      
    },
    table: {
    minWidth: 650,
  },
  }),
);


export default function FilterNfSimples() {
  const classes = useStyles();
  const [btnProcessar, SetBtnProcessar] = useState(false);
  const [values, setValues] = useState({
    chaves: ''
  });
  const { addToast } = useToasts();
  
    
  const handleSubimit  = async () => {

      const {files} = document.getElementById('file-up');

      if(values.chaves === '' &&  files.length === 0){
        addToast('Informe as chaves para consulta.', {
          appearance: 'warning',
          autoDismiss: true
        });
      }

        if(values.chaves !== ''){

          
          const request= {
            ChavesNF: values.chaves
          };    
        await  chaves({
            empresaid:'41251bf544de413ca6fe6d019a3c9ad3',
          request,
          onSuccess: (data) =>{
          },
        onError: (error) =>{
          console.log(error);
          addToast(error.message || error.reports, {
            appearance: 'error',
            autoDismiss: true
          });
        }});
      }
      else if(files.length > 0)
      {        
        const formData = new FormData();

        formData.append("file", files[0]);

       await file({
          empresaid:'41251bf544de413ca6fe6d019a3c9ad3',
          formData,
          onSuccess: (data) =>{
          },
        onError:(error) =>{
          console.log(error);
          addToast(error.message || error.reports, {
            appearance: 'error',
            autoDismiss: true
          });
        }});
      }
};

const handleChangeForm = name => event => {
  setValues({ ...values, [name]: event.target.value });
};

const handleUpload = () => {
  try
  {
    document.getElementById('file-up').click();
  }
  catch(ex)
  {
    console.log(ex);
    addToast(ex, {
      appearance: 'error',
      autoDismiss: true
    });
  } 
};

  return (
    <>
    <Grid spacing={2}>
    <Grid item spacing={1} >
    <TextField       
          disabled={btnProcessar}
          helperText="Campo obrigatório." 
          autoFocus
          margin="none"
          id="chaves"
          label="Chaves separado por vírgula"
          type="text"
          fullWidth
          onChange={handleChangeForm("chaves")}
        />
    </Grid>

    </Grid>
    <Grid container>   
    <Grid item xs={4} >        
    <InputFile id={'file-up'} accept={'.csv'} ></InputFile>
    <Button
      variant="contained"
      className={classes.button}
      startIcon={<GridOn />}
      onClick= {handleUpload}
    >
      Importar Csv
    </Button>
    </Grid>
    <Grid item xs={4} >        
    <Button
        variant="contained"
        className={classes.buttonSecond}
        startIcon={<FlashOn />}
        onClick= {handleSubimit}
      >
        Processar
      </Button>
    </Grid>
    </Grid>
  </>
  );

}
