import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import GridOn from '@material-ui/icons/GridOn';
import FlashOn from '@material-ui/icons/FlashOn';
import {  useToasts } from 'react-toast-notifications';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import { Container } from './styles';
import InputFile from '../../components/InputFile/index';
import ListSaldo from  './list/listSaldo';
import {salvarFile} from '../../service/consultaNFCompletaService';
import FilterSaldo from './filter/filterSaldo';
import {list} from '../../service/consultaSaldoService';


const ConsultaSaldo = ({empresa}) => {
  const [consultaSaldadata, setConsultaSaldodata] = useState([]);
  const { addToast } = useToasts();

  async function buscaDados(dados) {
    list({
      empresaid:"41251bf544de413ca6fe6d019a3c9ad3",
      request: {
        Porto: dados.porto,
        Cnpjs: dados.cnpjs.length > 0 ? dados.Cnpjs.split(',') : '',
        Ncm: dados.ncm,
        DataEmissaoInicial: dados.dataemissaoinicial,
        DataEmissaoFinal: dados.dataemissaofinal
      },
      onSuccess: (data) =>{
        console.log(data)
    setConsultaSaldodata(data.data);
      },
     onError: (error) =>{
    console.log("busca erro:",error);
    addToast(error.message || error.response.data, {
        appearance: 'error',
        autoDismiss: true
      });
     }})
 }




  return (
    <>
      <Container>
      <FilterSaldo buscaDados={buscaDados} />  
      <ListSaldo empresa={empresa} data={consultaSaldadata} />
      </Container>
    </>
  );
};

export default ConsultaSaldo;
