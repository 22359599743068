import { siscomex } from './instance'

export const file = async ({
  empresaid,
  formData,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) =>
{
 await siscomex.post(`/v1/empresa/${empresaid}/consulta_simples/file`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
})
.then(({ data }) => emitSuccessEvent(data))
.catch((error) => emitErrorEvent(error))
}


export const chaves = async ({
  empresaid,
  request,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) =>
{
await  siscomex.post(`/v1/empresa/${empresaid}/consulta_simples/chaves`, request)
.then(({ data }) => emitSuccessEvent(data))
.catch((error) => emitErrorEvent(error))
}
