import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
*{
margin:0;
padding:0;
box-sizing: border-box;
outline:0;
}

body { 
    background: #f5f7fb;
    color: rgb(56,56,66);
    -webkit-font-smoothing: antialiased;
}

body, input, button{
    font-family: 'Roboto' , serif;
    font-size: 16px;
}
h1,h2,h3,h4,h5,h6 strong{
    font-weight: 500;
}
button{
    cursor: pointer;
}

.css-1jgv6z-ToastContainer{
top: 64px !important;
}
`;
