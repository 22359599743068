import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  margin: 30px auto 0px auto;
  min-width: 900px;
  height: 1000px;
  display: table;
  padding: 50px;

`;
