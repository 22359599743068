import React from 'react';

import { Container } from './styles';
import ListProcessaNF from  '../processaNF/list/listProcessaNF';
import FilterNfCompleta from './filter/filterNFCompleta';



const ConsultaNFCompleta = ({empresa}) => {
  return (
    <>
      <Container>
        <FilterNfCompleta/>
        <ListProcessaNF empresa="" tipo="C"/>
      </Container>
    </>
  );
};

export default ConsultaNFCompleta;
