import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import GridOn from '@material-ui/icons/GridOn';
import FlashOn from '@material-ui/icons/FlashOn';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import { Container } from './styles';
import InputFile from '../../components/InputFile/index';
import ListProcessaNF from  '../processaNF/list/listProcessaNF';
import {salvarFile} from '../../service/consultaNFCompletaService';
import FilterNfSimples from './filter/filterNFSimples';

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      margin: theme.spacing(2),
      background: 'linear-gradient(83.07deg,#04580a 0%,#2E8B57 100%)',
      color: "#FFF"
    }, 
    buttonSecond: {
      margin: theme.spacing(2),
      background: 'linear-gradient(83.07deg,#041a58 0%,#3f51b5 100%)',
      color: "#FFF"
    },
    buttonSmall:{
        size: 'small',      
    },
    table: {
    minWidth: 650,
  },
  }),
);



const ConsultaNFSimples = ({empresa}) => {
  return (
    <>
      <Container>
      <FilterNfSimples/>  
      <ListProcessaNF empresa="" tipo="S" />
      </Container>
    </>
  );
};

export default ConsultaNFSimples;
