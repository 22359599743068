import { siscomex } from './instance'

export const listUrf = ({
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  siscomex
    .get(
      `/consultas/urf`
     
    )
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}