import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import GridOn from '@material-ui/icons/GridOn';
import FlashOn from '@material-ui/icons/FlashOn';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {  useToasts } from 'react-toast-notifications';
import { Container } from './styles';
import InputFile from '../../components/InputFile/index';
import ListProcessaNF from  './list/listProcessaNF';
import {salvarFile} from '../../service/consultaNFCompletaService';

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      margin: theme.spacing(2),
      background: 'linear-gradient(83.07deg,#04580a 0%,#2E8B57 100%)',
      color: "#FFF"
    }, 
    buttonSecond: {
      margin: theme.spacing(2),
      background: 'linear-gradient(83.07deg,#041a58 0%,#3f51b5 100%)',
      color: "#FFF"
    },
    buttonSmall:{
        size: 'small',      
    },
    table: {
    minWidth: 650,
  },
  }),
);
const ProcessaNF = ({empresa,tipo}) => {
  const classes = useStyles();
  const [btnProcessar, SetBtnProcessar] = useState(true);
  const { addToast } = useToasts();
  
  const handleUpload = () => {
    try
    {
      SetBtnProcessar(true);
      document.getElementById('file-up').click();
      SetBtnProcessar(false);
    }
    catch(ex)
    {
      console.log(ex);
      SetBtnProcessar(true);
    } 
  };
  
    
  const handleSubimit = async () => {
  
      SetBtnProcessar(true);
      const formData = new FormData();
      const file = document.getElementById('file-up');
  
      if(!file.files || file.files.length === 0)
      {
        addToast('Arquivo nulo ou inválido', {
          appearance: 'warning',
          autoDismiss: true
        });
      }
      else{
      formData.append("file", file.files[0]);
  
    await salvarFile({ 
                empresaid:"41251bf544de413ca6fe6d019a3c9ad3", 
                formData,
                onSuccess: (data) =>{
                  
                  addToast('Arquivo processado com sucesso.', {
                    appearance: 'success',
                    autoDismiss: true
                  });
                },
               onError: (error) => {
                console.log(error);
                addToast(error.message || error.reports, {
                  appearance: 'error',
                  autoDismiss: true
                });
               }});
              }
    
  };

  return (
    <>
    <Container>
    <Grid container spacing={2}>
    <Grid item >
    <Autocomplete 
      id="empresas"
      options={[]}
      getOptionLabel={(option) => option.title}
      style={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Empresa" variant="outlined" />}
    />
    </Grid>
    <Grid item >
    <InputFile id={'file-up'} accept={'.xls,.xlsx'} ></InputFile>
    <Button
      variant="contained"
      className={classes.button}
      startIcon={<GridOn />}
      onClick= {handleUpload}
    >
      Importar Excel
    </Button>
    </Grid>
    <Grid item >
    <Button
      disabled={btnProcessar}
      variant="contained"
      className={classes.buttonSecond}
      startIcon={<FlashOn />}
      onClick= {handleSubimit}
    >
      Processar Excel
    </Button>
    </Grid>
    </Grid>
    <ListProcessaNF empresa="" tipo="C" />
    </Container>
    </>
  );
};

export default ProcessaNF;
