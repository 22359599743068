import React from 'react';
import Button from '@material-ui/core/Button';
import {
  TableContainer,
  TableHead,
  TableBody,
  TableFooter,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Container } from './styles';
import NewEmpresa from './new/newEmpresa';
import ListEmpresa from './list/listEmpresa';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(2),
      background: "#2E8B57",
      color: "#FFF"
    },  
    buttonSmall:{
        size: 'small',      
    }
  }),
);


export default function Empresa() {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  
  const dataNF = [
    {
      Data: '07-08-2020',
      NFPrcessada: 21,
      NFFalha: 29,
    },
    {
      Data: '06-08-2020',
      NFPrcessada: 25,
      NFFalha: 25,
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <Container>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Novo
      </Button>
      <br/>
      <br/>
      <NewEmpresa open={open} setOpen={setOpen} />
      <ListEmpresa/>
      </Container>
  );
}