import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';

export default function NewUser(props : any) {
  const [state, setState] = React.useState({
    1: true,
    2: true,
    3: true,
    
  });

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleSave = () => {
  };

  const [
    hasError,
    setHasError
   ] = useState("");


   const handleIsVoid = (text: any, field: string) => {
     console.log(text);
     if(text.value === ""){
        setHasError(field);
     }
     else{
        setHasError("");
      }
  };
  
 const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const Empresas = [
    {
      id: "1",
      nome:"Matriz"
    },
    {
      id: "2",
      nome:"Filial 01"
    },
    {
      id: "3",
      nome:"Filial 02"
    }

  ]

  return (
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Usuário</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Informe os dados do usuário.
        </DialogContentText>
        <TextField        
          error={hasError === "email"}
          helperText="Campo obrigatório." 
          autoFocus
          margin="dense"
          id="email"
          label="E-mail"
          type="email"
          fullWidth
        />
        <TextField
          error={hasError === "nome"}
          helperText="Campo obrigatório."
          margin="dense"
          id="nome"
          label="Nome"
          type="text"
          fullWidth
        />        
        <TextField
          error={hasError === "password"}
          helperText="Campo obrigatório."
          margin="none"
          id="password"
          label="Senha"
          type="password"
          fullWidth
        />
        <FormGroup row>
          {Empresas.map(emp => 
               <FormControlLabel 
                key={emp.id}
                control={<Checkbox checked={state[1]}
                onChange={handleChange} 
                name={emp.nome} />}
                label={emp.nome}
                />
        )}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSave} color="primary">
          Gravar
        </Button>
      </DialogActions>
    </Dialog>
  );
  };