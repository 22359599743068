import React, { useState, useEffect } from 'react';
import MUIDataTable, {SelectableRows} from "mui-datatables";
import {listUrf} from '../../../service/urfServices';

export default function ListUrf() {
  const [urfdata, setUrfdata] = useState([]);

  useEffect(() => {
  
    listUrf({
      onSuccess: (data) => {
        setUrfdata(data.data);
      }
    });

  }, [])

  const columns = [
    {
     name: "codigo",
     label: "Codigo",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "descricao",
     label: "Descricao",
     options: {
      filter: true,
      sort: false,
     }
    }];

  const none : SelectableRows = "none";

  const options = {
    print: false,
    selectableRows: none
  };

  return (
        <MUIDataTable
          title={"Unidade Receita Federal"}
          data={urfdata}
          columns={columns}
          options={options}
        />
  );
}