import { siscomex } from './instance'

export const list = async  ({
  empresaid,
  request,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
 await  siscomex
    .post(
      `/v1/empresa/${empresaid}/consulta_saldo/search`,request
     
    )
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}
