import { siscomex } from './instance'

export const list =  ({
  empresaid,
  request,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
   siscomex
    .post(
      `/v1/empresa/${empresaid}/processa_nf/list`,request
     
    )
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}

export const download = async  ({
  empresaid,
  request,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  await siscomex
    .get(
      `/v1/empresa/${empresaid}/processa_nf/download?ProcessaNFId=${request.ProcessaNFId}&Tipo=${request.Tipo}`,
      {
        responseType : "blob"
      }
     
    )
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}
