import React, { useState, useEffect } from 'react';
import MUIDataTable, {SelectableRows} from "mui-datatables";
import { Button,TableFooter, TableCell, TableRow  } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { createStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      margin: theme.spacing(2),
      background: 'linear-gradient(83.07deg,#4f5804 0%,#8b882e 100%);',
      color: "#FFF"
    }, 
    buttonSecond: {
      margin: theme.spacing(2),
      background: 'linear-gradient(83.07deg,#041a58 0%,#3f51b5 100%)',
      color: "#FFF"
    },
    buttonSmall:{
        size: 'small',      
    },
    table: {
    minWidth: 650,
  },
  }),
);


export default function ListSaldo({empresa, data}) {
  const classes = useStyles();


const columns = [
{
 name: "porto",
 label: "Porto",
 options: {
 filter: true,
 }
},
{
 name: "fornecedor",
 label: "Fornecedor",
 options: {
display: 'true'
 }
},
{
  name: "ncm",
  label: "NCM",
  options: {
  filter: true,
  }
},
{
   name: "numero",
   label: "Numero NF",
   options: {
   filter: true,
   }
},
{
 name: "serie",
 label: "Serie NF",
 options: {
 filter: true,
 }
},
{
  name: "modelo",
  label: "Modelo NF",
  options: {
  filter: true,
  }
 },
{
name: "dataEmissao",
label: "Data Emissão",
options: {
display: 'true',
customBodyRenderLite: (dataIndex) => {
  const val = data.lista[dataIndex].dataEmissao;
  return moment(val).format("DD/MM/YYYY");
}
}
},
{
  name: "saldo",
  label: "Saldo",
  options: {
  filter: true
  }
 },
 {
   name: "pesoAferido",
   label: "Peso Aferido",
   options: {
   filter: true,
   }
  }
  ];

  const options = {
    print: true,
    selectableRows: "none"
  };

  return (
    <MUIDataTable
    title={"Consulta Saldo" + (data.saldoTotal !== undefined ? ` - Saldo Total : ${data.saldoTotal}` : '')}
    data={data.lista}
    columns={columns}
    options={options}
  />
  );
}