import React from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Container } from './styles';
import ListRecinto from './list/listRecinto';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(2),
      background: "#2E8B57",
      color: "#FFF"
    },  
    buttonSmall:{
        size: 'small',      
    }
  }),
);


export default function Recinto() {
  const classes = useStyles();
  


  const handleClickFind = () => {

  };

  return (
    <Container>
      <Button variant="outlined" color="primary" onClick={handleClickFind}>
        Buscar
      </Button>
      <br/>
      <br/>
      <ListRecinto/>
      </Container>
  );
}