import { siscomex } from './instance'

export const listEmpresa = ({
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  siscomex
    .get(
      `/v1/empresa`
     
    )
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}