import React, { useState, useEffect } from 'react';
import MUIDataTable, {SelectableRows} from "mui-datatables";
import { Button } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { createStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import {list,download} from '../../../service/processaNFServices';

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      margin: theme.spacing(2),
      background: 'linear-gradient(83.07deg,#4f5804 0%,#8b882e 100%);',
      color: "#FFF"
    }, 
    buttonSecond: {
      margin: theme.spacing(2),
      background: 'linear-gradient(83.07deg,#041a58 0%,#3f51b5 100%)',
      color: "#FFF"
    },
    buttonSmall:{
        size: 'small',      
    },
    table: {
    minWidth: 650,
  },
  }),
);


export default function ListProcessaNF({empresa,tipo}) {
  const classes = useStyles();
  const [consultaNfdata, setConsultaNfdata] = useState([]);

  useEffect( () => {
  
     list({
      empresaid: '41251bf544de413ca6fe6d019a3c9ad3',
      request: {tipo},
      onSuccess: (data) => {
        setConsultaNfdata(data.data);
      }
    });

  }, []);

  async function  handleDownload(id,Tipo){
   
   await download({
      empresaid: '41251bf544de413ca6fe6d019a3c9ad3',
      request: {ProcessaNFId : id,Tipo },
      onSuccess: (data) => {
        const file = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const fileURL = URL.createObjectURL(file);

        const link = document.createElement("a");
        link.href = fileURL;
        link.download = `ConsultaNF.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
      }
    });
    
  }

  const columns = [
    {
     name: "id",
     label: "id",
     options: {
      display: 'false'
     }
    },
    {
     name: "dataProcessamento",
     label: "Data Processamento",
     options: {
      filter: true,
      sort: false,
      customBodyRenderLite: (dataIndex) => {
        const val = consultaNfdata[dataIndex].dataProcessamento;
        return moment(val).format("DD/MM/YYYY");
      }
     }
    },
    {
     name: "descricao",
     label: "Empresa",
     options: {
      filter: true,
      sort: false,
      customBodyRenderLite: (dataIndex) => {
        const val = consultaNfdata[dataIndex].empresa.descricao;
        return val ;
      }
     }
    },
    {
     name: "tipo",
     label: "Tipo",
     options: {
      filter: true,
      sort: false,
      customBodyRenderLite: (dataIndex) => {
        const val = consultaNfdata[dataIndex].tipo;
        return val === "C" ? "Completa" : "Simples";
      }
     }
    },
    {
     name: "totalNF",
     label: "Total NF",
     options: {
      filter: true,
      sort: false,
     }
    },
    {
     name: "options",
     label: "options",
     options: {
      filter: false,
      sort: false,
      customBodyRenderLite: (dataIndex) => {
        const {id} = consultaNfdata[dataIndex];
        const {tipo :Tipo} = consultaNfdata[dataIndex];
        return <>    
        <Button
        variant="contained"
        className={classes.button}
        startIcon={<DownloadIcon />}
        onClick= { () => handleDownload(id,Tipo)}
        >
        Download
        </Button>
      </>;
      }
     }
    }
  ];


  const options = {
    print: false,
    selectableRows: "none"
  };

  return (
    <MUIDataTable
    title={"Consulta NF"}
    data={consultaNfdata}
    columns={columns}
    options={options}
  />
  );
}