import React, { useState, useEffect } from 'react';
import MUIDataTable, {SelectableRows} from "mui-datatables";
import {listEmpresa} from '../../../service/empresaServices';

export default function ListEmpresa() {
  const [empresadata, setEmpresadata] = useState([]);

  useEffect(() => {
  
    listEmpresa({
      onSuccess: (data) => {
        setEmpresadata(data.data);
      }
    });

  }, [])
  const columns = [
    {
     name: "cnpj",
     label: "Cnpj",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "razaoSocial",
     label: "Razão Social",
     options: {
      filter: true,
      sort: false,
     }
    }];
  
  const none : SelectableRows = "none";

  const options = {
    print: false,
    selectableRows: none
  };

  return (
    <MUIDataTable
    title={"Empresas"}
    data={empresadata}
    columns={columns}
    options={options}
  />
  );
}