import { siscomex } from './instance'

export const Salvar = async ({
  formData,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
await  siscomex
    .post(
      `/parametros`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}