import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  display: flex;
  min-width: 900px;
  align-items: center;

  iframe {
    min-width: 1280px;
    min-height: 800px;
  }
`;
